.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}



.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@import url("https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
body {
  font-family: 'Rubik', sans-serif;
}



.dropdown-toggle{
  color: white !important;
  }

#Analysis .dropdown-toggle {
  background-color: rgb(235, 88, 88);
  box-shadow:0 0 0 0.2rem rgb(235, 88, 88 / 50%);
  border-color:rgb(235, 88, 88);
  outline: 0;
}


.nav-pills .nav-link.active{
  background-color: rgb(235, 88, 88) !important;
}

a.nav-link {
  color:white !important;

}

body{
  background-color: rgb(133, 196, 202) !important;;
}